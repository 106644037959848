.leaflet-container {
    height: 100%;
    width: 100%;
}

.leaflet-top {
    top: 85%;
}

.leaflet-left {
    left: 95%;
}

.leaflet-div-icon{
	background-color: transparent;
	border: none;
}