.App {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background: #fff;
}

* {
	font-size: 14px;
	margin: 0;
	padding: 0;
	margin-block-start: 0;
	margin-block-end: 0;
	font-family: 'HK Grotesk Regular';
}

.container {
	padding: 30px 30px 0px 30px;
}

.sd {
	pointer-events: none;
}

@font-face {
	font-family: 'HK Grotesk Bold';
	src: local('HK Grotesk'), url(./assets/fonts/HKGrotesk-Bold.otf) format('opentype');
}
@font-face {
	font-family: 'HK Grotesk SemiBold';
	src: local('HK Grotesk'), url(./assets/fonts/HKGrotesk-SemiBold.otf) format('opentype');
}
@font-face {
	font-family: 'HK Grotesk Medium';
	src: local('HK Grotesk'), url(./assets/fonts/HKGrotesk-Medium.otf) format('opentype');
}
@font-face {
	font-family: 'HK Grotesk Regular';
	src: local('HK Grotesk'), url(./assets/fonts/HKGrotesk-Regular.otf) format('opentype');
}
@font-face {
	font-family: 'HK Grotesk Light';
	src: local('HK Grotesk'), url(./assets/fonts/HKGrotesk-Light.otf) format('opentype');
}

p,
span {
	word-break: break-word;
}
