@import '~@fortawesome/fontawesome-free/css/all.css';
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
	margin: 0;
	padding: 0;
}


div {
	box-sizing: border-box;
}

#root {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

.d-flex {
	display: flex;
}

.align_item_center{
	align-items: center;
}

.flex_center_content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex_between_center {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.text-align-center {
	text-align: center;
}

.pac-container {
	z-index: 1400 !important;
}

.cursor-pointer {
	cursor: pointer;
}

.cursor-unset {
	cursor: unset;
}

.disable_cursor{
	pointer-events: none;
	cursor: unset;
}

.dashed-line {
	margin-top: 5px;
	height: 2px;
	background: repeating-linear-gradient(90deg, #b29de2 0 10px, #0000 0 15px);
}

